.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input{
  border: none;
  border-bottom: 1px solid #e90909;
  padding: 20px 10px 0px;
  width: 28%;
  outline: none;
  font-size: 20px;
  margin: 10px;
  border-radius: 10px 10px 0px 0px;
}
input:focus{
  border-bottom: 3px solid #e90909;
  background: #80808008;
}
input[type="text"]::placeholder {
  text-align: center;
}
.input-div{
  display: flex;
  justify-content: center;
}
button{
  width: 30%;
  border: none;
  padding: 10px;
  color: red;
  background: black;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  outline: none;
}
.button-div{
  display: flex;
  justify-content: center;
}

button:active{
  background: #df0000;
  color: black;
}
.header-div{
  text-align: center;
  background: red;
  padding: 20px 0px;
  box-shadow: 0px 0px 10px 11px black;
  z-index: 10;
  position: fixed;
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  top: 0px;
}
.yt-form{
  margin-top: 10rem;
}
.product{
  width:120px;
  height:240px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  button{
    width: 100%;
  }
  input{
    width: 100%;
  }
}